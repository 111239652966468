import React from "react"
import PropTypes from "prop-types"

import DashHeader from "./dash-header"

import "../../styles/tailwind.css"

const LayoutDashboard = ({ children }) => {
  return (
    <>
      <DashHeader siteTitle="Cedilar" />
      <main className=" mx-auto bg-dashBg">{children}</main>
    </>
  )
}

LayoutDashboard.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutDashboard
