import React from "react"

import SEO from "../seo"

const Dashboard = () => (
  <>
    <SEO title="Home" />
    <h1>THis is the dashboard</h1>
  </>
)

export default Dashboard
