import React from "react"
import { Router } from "@reach/router"

import LayoutDashboard from "../components/admin-dash/layout-dashboard"

import Dashboard from "../components/admin-dash/dashboard"
import Login from "../components/admin-dash/login"
import PrivateRoute from "../components/admin-dash/private-route"

const Admin = () => (
  <LayoutDashboard>
    <Router basepath="/admin">
      <PrivateRoute path="/dashboard" component={Dashboard} />
      <Login path="/login" />
    </Router>
  </LayoutDashboard>
)

export default Admin
