import React from "react"

const DashHeader = ({ siteTitle }) => (
  <header className="p-4 bg-blue-600">
    <div className="container flex mx-auto justify-between">
      <div className="flex text-white">
        <svg
          className="fill-current h-8 w-8 mr-2"
          width="60"
          height="60"
          viewBox="0 0 54 54"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M13.5 22.1c1.8-7.2 6.3-10.8 13.5-10.8 10.8 0 12.15 8.1 17.55 9.45 3.6.9 6.75-.45 9.45-4.05-1.8 7.2-6.3 10.8-13.5 10.8-10.8 0-12.15-8.1-17.55-9.45-3.6-.9-6.75.45-9.45 4.05zM0 38.3c1.8-7.2 6.3-10.8 13.5-10.8 10.8 0 12.15 8.1 17.55 9.45 3.6.9 6.75-.45 9.45-4.05-1.8 7.2-6.3 10.8-13.5 10.8-10.8 0-12.15-8.1-17.55-9.45-3.6-.9-6.75.45-9.45 4.05z" />
        </svg>
        <span className="font-semibold text-2xl tracking-tight">
          {siteTitle}
        </span>
      </div>

      <span className="flex items-center text-white">Sair</span>
    </div>
  </header>
)

export default DashHeader
